.page-dish {
    display: flex;
    width: 100%;
    // height: calc(100vh - 282px);
    height: calc(100vh - 212px);
    margin-bottom: 24px;
    .content-dish {
      width: calc(100%);
      display: flex;
      flex-direction: row;
      input:focus-visible {
        outline: none;
      }
      .header-search {
        display: flex;
        justify-content: space-between;
        padding: 20px 20px;
        .btn-menu {
          display: flex;
          align-items: center;
          .icon-menu {
            margin-right: 12px;
            width: 36px;
            height: 36px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #ffffff;
            border-radius: 100px;
          }
          .label-menu {
            color: #202223;
            font-size: 24px;
            font-weight: 600;
          }
        }
        .search {
          box-sizing: border-box;
    
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 8px;
          gap: 10px;
    
          width: 280px;
          height: 40px;
          border-bottom: 1px solid #c9cccf;
          input {
            background: none;
            border: none;
          }
          input:focus {
            border: none;
            background: none;
          }
        }
      }
      .content-tag {
        margin-top: 24px;
        display: flex;
        .item {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 10px 15px;
          height: 28px;
          background: #dfe2e5;
          border-radius: 100px;
          font-weight: 500;
          font-size: 14px;
          margin-right: 16px;
          .label {
            margin-left: 5px;
          }
        }
        .item-active {
          background: #039754;
          border-radius: 100px;
          color: #fdfdfd;
        }
      }
      .dish {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        overflow-y: scroll;
        height: 100%;
    
        // margin: -12px 0 0 -12px;
        // width: calc(100% + 12px);
        .item {
          margin: 15px;
          cursor: pointer;
          border-radius: 8px;
          background: #fff;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
          border-radius: 16px;
          justify-content: center;
          width: 164px;
          .image {
            margin-top: -1px;
            width: 100%;
            display: flex;
            justify-content: center;
            border-top-right-radius: 8px;
            border-top-left-radius: 8px;
          }
          .info {
            padding: 0px 16px;
            .label {
              height: 36.5px ;
              margin-top: 15px;
              font-weight: 600;
              font-size: 14px;
              margin-bottom: 5px;
              text-align: center;
              color: #202223;
            }
            .price {
              color: #DC8108;
              text-align: center;
              font-weight: 500;
              font-size: 12px;
              padding-bottom: 10px;
            }
          }
        }
        .item-active {
          .label {
            font-weight: 600;
            font-size: 14px;
            text-align: center;
            color: #202223;
          }
        }
      }
    }
  }

    .txt-info {
        display: -webkit-box;
        line-height: 1.3;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
