#page-split-order{
    width: 100%;
    height: 100%;
    background-color: #F0F0F0;
    padding: 24px;
    justify-content: space-between;
    display: flex;
}
.order-part
{
    width: 49%;
    background-color: #FFFFFF;
    border-radius: 8px;
}
.order-no-contain{
    padding: 16px;
    border-bottom: 1px solid #F0F0F0;
    .text-order-no{
        font-size: 12px;
        font-weight: 400;
        color: #7A7A7B;
        margin-bottom: 4px;
    }
    .code-order-no{
        font-size: 16px;
        font-weight: 600;
        color: #0A0B0F;
    }
}
