              
 @import '../../../../assets/styles/';
 .container-table-flow {
    // position: relative;
    width: 200px;
    height: 150px;
    cursor: pointer;
    position: relative;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size:auto;
    background-position: center;
    flex-direction: column;
    .bg-circle {
        width: 70px;
        height: 70px;
        border-radius: 60px;
        justify-content: center;
        display: flex;
        align-items: center;
        position: absolute;
        transform: none;
        .input-container {
            border: 0px !important;
        }
        .input-styles { 
            border: 0px !important;
            background-color: $trans;
            border-radius: 60px;
            justify-content: center;
            align-items: center;
            display: flex;
            text-align: center;
            z-index: 100;
            text-transform: uppercase;
        }
    }
      
  .rotate-handle {
    position: absolute;
    width: 200px;
    height: 150px;
    left: 100px;
    top: 75px;
    transform: translate(-50%, -50%);
    cursor: alias;
  }

  .rotate-line {
    position: absolute;
    width: 10px;
    height: 10px;
    background: $brown-2;
    left: 50%;
    top: -30px;
    border-radius: 100%;
    transform: translate(-50%, -50%);
    cursor: alias;
  }
  
  .rotate-line:after {
    display: block;
    position: absolute;
    width: 1px;
    height: 50px;
    background: $brown-2;
    left: 50%;
    top: 5px;
  }
 }
