@import '../../assets/styles/';

.popup-err{
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    .popup-print-container{
        background-color: $white;
        border-radius: 8px;
        padding: 24px;
        min-width: 320px;
        display: flex;
        flex-direction: column ;
        .popup-print-top{
            width: 100%;
            .header{
                display: flex;
                align-items: center;
            }
        }
        .button-contain{
            margin-top: 32px;
            display: flex;
            justify-content: space-between;
            .button-right{
                display: flex;
                flex: 1;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                font-weight: 600;
                color: $gray-1;
                background-color: $red;
                border-radius: 8px;
                height: 44px;
                cursor: pointer;
            }
        }
    }
}
