@import '../../assets/styles/';

.date-picker-container {
    background-color: $white;
    height: 40px;
    border-radius: 5px;
}

.input-date {
    background-color: $white;
    overflow: none;
    height: 40px;
    border: none;
    padding: 8px 16px;
    border-radius: 5px;
    width: 200px;
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.calendar {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 250px;
}
.date {
    position: absolute;
    top: 40px;
    left: 0px;
    z-index: 10000;
    width: 300px;

}

.view-left {
    display: flex;
    flex-direction: row;
}
