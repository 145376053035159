@import '../../../../assets/styles/';

.container-number-flow{
   background-color: $gray-2;
   justify-content: center;
   align-items: center;
   display: flex;
   padding: 5px;
   border-radius: 3px;
}
