::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #dedee0;
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #dedee0;
  }
  .page-login {
    background-color: #ffffff;
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    gap: 32px;
    padding-top: 100px;
    .title {
      width: 362px;
      display: flex;
      flex-direction: column;
      gap: 24px;
      .main-title {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 150%;
        text-align: center;
        color: #101828;
      }
      .support-title {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        color: #667085;
      }
    }
  
    .login-form {
      display: flex;
      flex-direction: column;
      width: 362px;
      gap: 24px;
      border-radius: 12px;
      label {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: #344054;
      }
      input {
        padding: 10px 14px;
        gap: 8px;
        width: 362px;
        height: 44px;
        background: #ffffff;
        border: 1px solid #d0d5dd;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
      }
      .username {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 8px;
        color: #344054;
      }
      .password {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 8px;
        color: #344054;
      }
      .sign-in {
        padding: 12px 24px;
        gap: 10px;
        width: 362px;
        height: 48px;
        background: #A6815B;
        border-radius: 8px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        color: #ffffff;
      }
    }
  }
  .main-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    gap: 224px;
    background: #a6815b;
    position: fixed;
    width: 100%;
    max-width: 1194px;
    margin: auto;
    .title {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
      gap: 24px;
  
      width: 313px;
      .table-title {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 120%;
        color: #fdfdfd;
      }
    }
    .right-header {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;
      gap: 40px;
  
      width: 395px;
      .dropdown {
        margin: auto;
        cursor: pointer;
        .dropdown-label {
          display: flex;
          position: relative;
  
          input {
            display: flex;
  
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            // padding: 12px 16px;
            gap: 10px;
  
            // border: 1px solid #DFE2E6;
            // border-radius: 8px;
            background: #fdfdfd;
            color: #202223;
          }
        }
        cursor: pointer;
        .option {
          display: flex;
          // display: none;
          width: 200px;
          right: 7px;
          flex-direction: column;
          align-items: center;
          padding: 16px;
          gap: 16px;
          position: absolute;
          margin-top: 10px;
          background-color: #ffffff;
          border-radius: 8px;
          color: #202223;
          gap: 16px;
          div {
            width: 100%;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            padding: 0px 0px 8px;
            display: flex;
            align-items: center;
            text-align: left;
            height: 28px;
            color: #202223;
            border-bottom: 1px dashed #dfe2e5;
          }
        }
      }
      .location {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 16px;
        color: #fdfdfd;
        width: 315px;
        justify-content: center;
        margin: auto;
        height: 40px;
        // cursor: pointer;
        .dropdown {
          .textbox {
            color: #fdfdfd;
            cursor: pointer;
            height: 40px;
          }
          // &::before {
          //   content: '';
          //   position: absolute;
          //   border: 2px solid #FDFDFD;
          //   z-index: 100000;
          //   width: 12px;
          //   height: 12px;
          //   right:20px;
          //   top: 11px;
          //   border-top: 2px solid #A6815B;
          //   border-right: 2px solid #A6815B;
          //   transform: rotate(-45deg);
          // }
          input {
            display: flex;
  
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 12px 16px;
            gap: 10px;
            width: 232px;
  
            border: 1px solid #dfe2e6;
            border-radius: 8px;
            background: none;
            color: #fdfdfd;
          }
          cursor: pointer;
          .option {
            display: flex;
            // display: none;
            flex-direction: column;
            align-items: center;
            padding: 16px;
            gap: 16px;
            width: 100%;
            position: absolute;
            left: 0px;
            margin-top: 10px;
            background-color: #ffffff;
            box-shadow: 0px 4px 8px -2px rgba(32, 34, 35, 0.1),
              0px 2px 4px -2px rgba(32, 34, 35, 0.06);
            border-radius: 8px;
            color: #202223;
            gap: 16px;
            height: calc((100vh - 200px));
            overflow-y: scroll;
            div {
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              padding: 0px 0px 8px;
              display: flex;
              align-items: center;
              text-align: left;
              height: 28px;
              color: #202223;
              border-bottom: 1px dashed #dfe2e5;
              &:hover {
                color: #caa37d;
              }
              // &::selection {
              //   color: #CAA37D;
              // }
              // &:visited {
              //   color: #CAA37D;
              // }
            }
          }
        }
        .location-list {
          option {
            color: #202223;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px 0px 8px;
            gap: 12px;
  
            width: 200px;
            border-bottom: 1px dashed #dfe2e5;
          }
        }
      }
      .avatar {
        display: flex;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        width: 40px;
        height: 40px;
        margin: auto;
        padding: auto;
        /* Background/0 */
        text-align: center;
        justify-content: center;
        background: #fdfdfd;
        border: 2px solid rgba(202, 163, 125, 0.5);
        border-radius: 200px;
        color: #202223;
        cursor: pointer;
      }
    }
  }
  input {
    &:focus-visible {
      border: 0px;
      outline: 0px;
    }
  }
  // css phan popup theo ban
  .popup-window {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 40vw;
    gap: 32px;
    text-align: left;
    overflow: hidden;
  
  
  
    // position: absolute;
    // // width: 320px;
    // // margin: auto;
    // position: fixed;
    // top: 230px;
    // // left: 50%;
  
  
    max-height: 89vh;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 350px;
    background: #ffffff;
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
      0px 8px 8px -4px rgba(16, 24, 40, 0.03);
    border-radius: 12px;
  }
  .modal-dialog{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .modal-content{
    width: 330px !important;
    padding: auto;
    max-height: 89vh;
    max-width: 90vw;
    gap: 32px;
    text-align: left;
    overflow: hidden;
    background: #ffffff;
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
      0px 8px 8px -4px rgba(16, 24, 40, 0.03);
    border-radius: 12px;
  }
  
  
  .cover-body {
    width: 320px;
    overflow-y: scroll;
    .order-list-body {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 20px;
      // overflow-y: scroll;
      .order-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0px 0px 12px;
        gap: 12px;
  
        width: 100%;
        height: 32px;
  
        /* Divider/2 */
  
        border-bottom: 1px dashed #dfe2e5;
        .order-check {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding: 0px;
          gap: 12px;
          // cursor: pointer;
          min-width: 66px;
          max-width: 200px;
          overflow: hidden;
          text-overflow: ellipsis;
          label{
            max-width: 1500px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .move-to-order {
          color: #047d46;
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0px;
          gap: 2px;
          cursor: pointer;
        }
      }
    }
  }
  
  .page-order-dish {
    display: flex;
    width: 100%;
    height: calc(100vh - 76px);
    // height: 100%;
  }
  
  .add-or-edit-modal {
    &:focus-visible {
      border: 0px;
    }
    .content {
      background: #ffffff;
      box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
        0px 8px 8px -4px rgba(16, 24, 40, 0.03);
      border-radius: 12px;
      &:focus-visible {
        border: 0px;
        outline: 0px;
      }
      .header-modal {
        min-height: 60px;
        // border-bottom: 1px solid #0000001f;
        padding: 10px 20px;
        .title {
          font-weight: 600;
          font-size: 20px;
        }
        .close-icon {
          cursor: pointer;
        }
      }
      .content-modal {
        padding: 20px;
        .txt-description {
          width: 100%;
          border: 1px solid #0000001f;
          border-radius: 5px;
          padding: 10px;
          min-height: 70px;
          &:focus-visible {
            outline: 0px;
          }
        }
        .MuiSelect-outlined {
        }
      }
      .footer-modal {
        // border-top: 1px solid #0000001f;
        display: flex;
        justify-content: flex-end;
        padding: 20px 20px;
        align-items: center;
      }
      .MuiOutlinedInput-root {
        .MuiOutlinedInput-input {
          padding: 10px 14px;
        }
      }
    }
  }
  input::selection {
    border: none;
  }
  
  .content-dish {
    width: calc((100% - 366px));
    display: flex;
    flex-direction: row;
    input:focus-visible {
      outline: none;
    }
    .header-search {
      display: flex;
      justify-content: space-between;
      padding: 20px 20px;
      .btn-menu {
        display: flex;
        align-items: center;
        .icon-menu {
          margin-right: 12px;
          width: 36px;
          height: 36px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #ffffff;
          border-radius: 100px;
        }
        .label-menu {
          color: #202223;
          font-size: 24px;
          font-weight: 600;
        }
      }
      .search {
        box-sizing: border-box;
  
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px;
        gap: 10px;
  
        width: 280px;
        height: 40px;
        border-bottom: 1px solid #c9cccf;
        input {
          background: none;
          border: none;
        }
        input:focus {
          border: none;
          background: none;
        }
      }
    }
    .content-tag {
      margin-top: 24px;
      display: flex;
      .item {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 15px;
        height: 28px;
        background: #dfe2e5;
        border-radius: 100px;
        font-weight: 500;
        font-size: 14px;
        margin-right: 16px;
        .label {
          margin-left: 5px;
        }
      }
      .item-active {
        background: #039754;
        border-radius: 100px;
        color: #fdfdfd;
      }
    }
    .dish {
      flex-direction: row;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      overflow-y: scroll;
      height: calc(100vh - 186px);
  
      // margin: -12px 0 0 -12px;
      // width: calc(100% + 12px);
      .item {
        margin: 15px;
        cursor: pointer;
        border-radius: 8px;
        background: #fff;
        box-shadow: 0px 4px 8px -2px rgba(32, 34, 35, 0.1),
          0px 2px 4px -2px rgba(32, 34, 35, 0.06);
        border-radius: 16px;
        justify-content: center;
        width: 164px;
        .image {
          margin-top: -1px;
          width: 100%;
          display: flex;
          justify-content: center;
          border-top-right-radius: 8px;
          border-top-left-radius: 8px;
        }
        .info {
          .label {
            margin-top: 15px;
            font-weight: 600;
            font-size: 14px;
            margin-bottom: 5px;
            text-align: center;
            color: #202223;
          }
          .price {
            color: #6d7175;
            text-align: center;
            font-weight: 500;
            font-size: 12px;
            padding-bottom: 10px;
          }
        }
      }
      .item-active {
        .label {
          font-weight: 600;
          font-size: 14px;
          text-align: center;
          color: #202223;
        }
      }
    }
  }
  .content-order {
    background-color: #fff;
    height: 100%;
    position: relative;
    width: 366px;
    .header {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 20px 20px 0px 20px;
      .first-header {
        display: flex;
        justify-content: space-between;
        .name-table {
          color: #202223;
          font-size: 24px;
          font-weight: 700;
          max-width: 200px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .header-right-order {
          .time {
            display: flex;
            justify-content: center;
            align-items: center;
            background: #f0f1f2;
            border-radius: 100px;
            font-weight: 500;
            font-size: 12px;
            margin-left: 10px;
            padding: 5px 10px;
            gap: 5px;
            color: white;
            background-color: #f79009;
          }
          margin-bottom: 15px;
        }
      }
      .header-below-order {
        display: flex;
        .location {
          background: #f0f1f2;
          border-radius: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 500;
          font-size: 12px;
          padding: 5px 10px;
          gap: 5px;
        }
        .meal {
          display: flex;
          justify-content: center;
          align-items: center;
          background: #f0f1f2;
          border-radius: 100px;
          font-weight: 500;
          font-size: 12px;
          margin-left: 10px;
          padding: 0px 10px;
        }
      }
    }
    .content {
      padding: 0px 20px 20px 20px;
      .lable {
        margin-top: 24px;
        display: flex;
        justify-content: space-between;
        color: #474a4c;
        font-size: 14px;
        font-weight: 600;
      }
      // css cho scrool bar
      .scrollbar {
        background-color: #f5f5f5;
        float: left;
        height: 300px;
        margin-bottom: 25px;
        margin-left: 22px;
        margin-top: 40px;
        width: 65px;
        overflow-y: scroll;
      }
      #style-1::-webkit-scrollbar {
        width: 6px;
        background-color: #f5f5f5;
      }
      #style-1::-webkit-scrollbar-thumb {
        background-color: #000000;
      }
      .force-overflow {
        min-height: 450px;
      }
      .item-list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 20px;
        width: 100%;
        height: 440px;
        overflow-y: scroll;
        /* Inside auto layout */
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
        .item {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-end;
          padding: 5px;
          width: 100%;
          .font-big {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
          }
          .font-normal {
            color: #6d7175;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 150%;
          }
          .main-item {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 0px;
            gap: 24px;
            justify-content: space-between;
            width: 100%;
            height: auto;
            .item-info {
              display: flex;
              flex-direction: row;
              align-items: flex-start;
              padding: 0px;
              gap: 12px;
            }
            .item-value {
              .item-quantity {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                // gap: 10px;
                text-align: center;
                gap: 5px;
              }
            }
          }
          .note {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 0px;
            width: 100%;
            justify-content: space-between;
            .note-style {
              margin-top: 5px;
              font-style: italic;
              border: none;
              width: 170px;
              overflow: hidden;
              resize: none;
              // margin-left: 10px;
              &:focus-visible {
                outline: none;
              }
            }
  
            .note-label {
              color: #CAA37D;
              font-weight: 400;
              font-size: 12px;
              line-height: 150%;
            }
            .note-icon {
              margin-bottom: 10px;
              line-height: 150%;
              display: flex;
              gap: 5px;
              cursor: pointer;
            }
          }
        }
      }
    }
  
    .footer {
      padding: 12px 15px 15px;
      bottom: calc(100% - 98%);
      background: #fdfdfd;
      position: absolute;
      width: 100%;
      box-shadow: 0px -2px 8px -2px rgba(32, 34, 35, 0.1),
        0px 0px 4px -2px rgba(32, 34, 35, 0.06);
      .total-value {
        height: 100%;
        padding: 10px 0;
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
        gap: 12px;
        .total-people {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start;
          padding: 0px;
          gap: 6px;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
        }
        .total-price {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding: 0px;
          gap: 10px;
          flex: none;
          order: 0;
          align-self: stretch;
          flex-grow: 0;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 150%;
        }
      }
      .order-button {
        padding: 12px 24px;
        color: #ffffff;
        gap: 10px;
        width: 100%;
        background: #a6815b;
        background: #a6815b;
        border-radius: 8px;
        border: none;
      }
    }
  }
  
  // Custom Menu
  .menu-content2 {
    width: 0px;
    transform: translateX(-1000px);
    transition: 0.5s;
    background: #fdfdfd;
    box-shadow: 0px 12px 16px -4px rgba(32, 34, 35, 0.08),
      0px 4px 6px -2px rgba(32, 34, 35, 0.03);
    .title-menu {
      display: flex;
      flex-direction: row;
      padding-top: 30px;
      padding-bottom: 18px;
      padding-left: 18px;
      border-bottom: solid 1px#DFE2E5;
      align-items: center;
      img {
        margin-right: 5px;
      }
    }
    .title-cate {
      background-color: #caa37d;
      color: #fdfdfd;
      font-size: 16px;
      font-weight: 600px;
      justify-content: space-between;
      display: flex;
      flex-direction: row;
      padding: 6px 16px;
      border-radius: 6px 6px 0px 0px;
    }
    .title-cate-notchoose {
      padding: 6px 16px;
      color: #202223;
      font-size: 16px;
      font-weight: 600px;
      justify-content: space-between;
      display: flex;
      flex-direction: row;
    }
    .cate {
      padding: 12px 16px;
      border-radius: 6px;
    }
    .title-sub-cate {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 12px 16px;
      cursor: pointer;
    }
    .detail-cate {
      background-color: #a6815b1a;
      border-radius: 6px;
    }
    .subItemChoose {
      padding-left: 32px;
      color: #caa37d;
    }
    .title-sub-cate {
      color: #caa37d;
    }
  }
  .menu-content1 {
    width: 244px;
    transform: translateX(0px);
    transition: 0.5s;
    background: #fdfdfd;
    box-shadow: 0px 12px 16px -4px rgba(32, 34, 35, 0.08),
      0px 4px 6px -2px rgba(32, 34, 35, 0.03);
    .title-menu {
      display: flex;
      width: 100%;
      flex-direction: row;
      padding-top: 30px;
      padding-bottom: 18px;
      padding-left: 18px;
      border-bottom: solid 1px#DFE2E5;
      align-items: center;
      img {
        margin-right: 5px;
      }
    }
    .title-cate {
      width: 100%;
      background-color: #caa37d;
      color: #fdfdfd;
      font-size: 16px;
      font-weight: 600px;
      justify-content: space-between;
      display: flex;
      flex-direction: row;
      padding: 6px 16px;
      border-radius: 6px 6px 0px 0px;
    }
    .title-cate-notchoose {
      padding: 6px 16px;
      color: #202223;
      font-size: 16px;
      font-weight: 600px;
      justify-content: space-between;
      display: flex;
      flex-direction: row;
    }
    .cate {
      padding: 12px 16px;
      border-radius: 6px;
    }
    .title-sub-cate {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 12px 16px;
    }
    .detail-cate {
      background-color: #a6815b1a;
      border-radius: 6px;
    }
    .subItemNotChoose {
      padding-left: 32px;
      color: #474a4c;
      li::marker {
        content: "";
      }
    }
    .subItemChoose {
      padding-left: 32px;
      color: #caa37d;
    }
    .title-sub-cate {
      color: #caa37d;
    }
  }
  .empty-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .title-empty {
    font-size: 14px;
    font-weight: 400;
    color: #6d7175;
  }
  .sub-menu-scroll {
    max-height: 300px;
    overflow: auto;
    cursor: pointer;
  }
  .info {
    height: 70px;
  }
  .add-or-edit-modal .__content {
    width: 400px;
    height: 380px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .footer_popup {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 10px;
    [type="button"]:not(:disabled),
    [type="reset"]:not(:disabled),
    [type="submit"]:not(:disabled),
    button:not(:disabled) {
      width: 200px;
    }
  }
  .add-or-edit-modal .content .footer-modal {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .popup_modal {
    input,
    textarea {
      padding: 12px;
      border-radius: 12px;
      border: 1px solid #DFE0E2;
      // border-color: #dedee0 !important;
      margin-top: 5px;
      width: 100%;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
    }
  }
  .empty-cate{
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 227px);
  }
  .image-empty{
    // width: 156px;
    // height: 147px;
  }
  .loader {
    border: 2px solid #f3f3f3;
    border-top: 2px solid #3498db;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 2s linear infinite;
    margin: auto;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  