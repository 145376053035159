@import '../../../assets/styles/';
.page-order-detail{
    display: flex;
    padding: 24px;
    flex-direction: row;
    justify-content: space-between;
    .MuiTabPanel-root {
        padding: 0px;
    }
    .Mui-selected {
        color: $orange !important;
        .tab-list {
                svg {
                    fill: orange !important;
                }   
        }
    }
    .MuiTabs-indicator {
        background-color: $orange;
    }
}

.page-view {
    display: flex;
    flex-direction: column;
}

.tab-list {
    .MuiButtonBase-root {
        text-transform: none;
        svg {
            margin-right: 5px !important;
            margin-bottom: 2px !important;
        }
    }
}

.order-child {
    max-height: calc(100vh - 260px);
    overflow-y: auto;
}

.jus-center {
    justify-content: center;
}

.icon-text {
    margin-right: 8px;
}

.bg-white {
    background-color: $white;
}

.text-green {
    padding: 1px 8px;
    background-color: $green;
    border-radius: 8px;
    color: $white !important;
    font-size: 12px !important;
}

.text-red{
    padding: 1px 8px;
    background-color: $red;
    border-radius: 8px;
    color: $white !important;
    font-size: 12px !important;
}

.text-pri {
    color: $primary !important;
}

.between {
    justify-content: space-between;
    width: 100%;
}

.line {
    border-bottom: 1px solid $line;
}

.txt-yellow {
  color: $yellow-1;
}

.txt-red {
    color: $red;
    font-size: 16px !important;
}

.txt-bold {
    color: $black;
    font-size: 16px !important;
}

.view-right {
    width: calc(40% - 24px);
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 180px);
    overflow-y: auto;
}
