@import '../../assets/styles/';

.popup-print-order{
    width: fit-content;
    height: calc(100vh - 32px);
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: $white;
    padding: 16px;
    &:focus-visible {
        border: 0px;
        outline: 0px;
      }
    .button-contain{
        margin-top: 32px;
        display: flex;
        justify-content: space-between;
        .button-left{
            display: flex;
            flex: 0.45;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            font-weight: 600;
            color: $black;
            border: 1px solid $color-border;
            border-radius: 4px;
            height: 44px;
            width: 100px;
            cursor: pointer;
        }
        .button-right{
            display: flex;
            flex: 0.45;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            font-weight: 600;
            color: $gray-1;
            background-color: $brown-2;
            border-radius: 4px;
            height: 44px;
            width: 100px;
            cursor: pointer;
        }
    }
    .popup-print-container{
        background-color: $white;
        border-radius: 8px;
        padding: 24px;
        min-width: 320px;
        position: relative;
        max-height: 90vh;
        overflow-y: auto;
        // top: 50px;

        .body {
            height: fit-content !important;
        }
        .popup-print-top{
            width: 100%;
         
            .header{
                display: flex;
                align-items: center;
            }
        }
        
    }
}
