@import '../../assets/styles/';

.page-order-paid{
    display: flex;
    padding: 24px;
    flex-direction: column;
    .MuiTabPanel-root {
        padding: 0px;
    }
    .padding {
        padding-left: 16px;
    }
}

.page-view {
    display: flex;
    flex-direction: column;
}

.tab-list {
    .MuiButtonBase-root {
        text-transform: none;
    }
}

.btn {
    padding: 0px;
    margin-bottom: 16px;
    width: 200px;
    height: 40px;
    border: 1px solid $color-border !important;
    .MuiButtonBase-root {
      background-color: $white;
      box-shadow: none;
      border: none;
      justify-content: start;
      width: 100%;
      height: 100%;
    }

  }

.table-view {
    max-height: calc(100vh - 244px);
    overflow-y: auto;
}

.input{
 display: flex;
 flex-direction: row;
 padding: 5px 16px;
 background-color: $white;
 border-radius: 5px;
 position: relative;
 height: 40px;
 width: 28vw;
 text-transform: uppercase;
}

.jus-between {
    display: flex;
    justify-content: space-between;
}

.input-style {
    border: none;
}

.order-paid-ic-right {
    position: absolute;
    right: 16px;
}
