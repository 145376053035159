@import '../../assets/styles/';
#page-payment{
    width: 100%;
    height: 100%;
    background-color: $gray-1;
    padding: 15px 24px;
    display: flex;
    justify-content: space-between;
    .payment-left{
        width: 59%;
        .info-container{
            background-color: $white;
            padding: 16px;
            border-radius: 8px;
            .text-order{
                font-size: 20px;
                font-weight: 400;
                color: $black;
                margin-bottom: 16px;
            }
            .row-detail{
                display: flex;
                justify-content: space-between;
                .detail-part{
                    display: flex;
                    align-items: center;
                    .part-text{
                        margin-right: 8px;
                        color: $gray-2;
                        font-size: 14px;
                    }
                    .part-result{
                        font-size: 14px;
                        font-weight: 600;
                        color: $black;
                    }
                    img{
                        margin-right: 8px;
                    }
                    .part-result-name{
                        font-size: 14px;
                        font-weight: 600;
                        color: $brown-2;
                    }
                }
            }
            .row-note{
                margin-top: 16px;
                display: flex;
                .text-note{
                    font-size: 14px;
                    font-weight: 500;
                    color: $gray-2;
                    margin-right: 12px;
                }
                .detail-note{
                    font-size: 14px;
                    font-weight: 400;
                    font-style: italic;
                    color: $black;
                }
            }
        } 
        .order-list-container{
            background-color: $white;
            padding: 16px;
            border-radius: 8px;
            margin-top: 24px;
            max-height:  calc(100vh - 230px);
            overflow-y: auto;
            .order-list-text{
                margin-bottom: 16px;
                font-size: 20px;
                font-weight: 400;
                color: $black;
            }
        }
    }  
   
}

 .payment-right{
        width: 39%;
        max-height: 90%;
        overflow-y: auto;
        .discount-container{
            background-color: $white;
            padding: 16px;
            border-radius: 8px;
            position: relative;
            .discount-title{
                font-size: 20px;
                font-weight: 400;
                color: $black;
            }
            .checkbox-contain{
                margin-top: 16px;
                margin-bottom: 16px;
                .label-checkbox{
                    font-size: 14px;
                    font-weight: 500;
                    color: $gray-2;
                }
                .row-checkbox{
                    display: flex;
                    justify-content: space-between;
                    width: 50%;
                    margin-top: 10px;
                }
            }
            .row-data-detail{
                margin-top: 16px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                .row-label{
                    font-size: 14px;
                    font-weight: 500;
                    color: $gray-2;
                }
                .row-value{
                    font-size: 14px;
                    font-weight: 600;
                    color: $black;
                }
            }
            .discount-footer{
                margin-top: 16px;
                border-top: 1px solid $white;
                .total{
                    display: flex;
                    justify-content: space-between;
                    .total-text{
                        font-size: 16px;
                        font-weight: 500;
                        color: $black;
                    }
                    .total-value{
                        font-size: 16px;
                        font-weight: 600;
                        color: $red;
                    }
                }
                .buttons{
                    display: flex;
                    justify-content: space-between;
                    flex-direction: row;
                    margin-top: 24px;
                    .button-print{
                        width: 49%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border: 1px solid $color-border;
                        border-radius: 5px;
                        height: 44px;
                        font-weight: 600;
                        font-size: 14px;
                        color: $black;
                        cursor: pointer;
                    }
                    .button-payment{
                        width: 49%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-color: $brown-2;
                        border: 1px solid $color-border;
                        border-radius: 5px;
                        height: 44px;
                        font-weight: 600;
                        font-size: 14px;
                        color: $gray-1;
                        cursor: pointer;
                    }
                }
            }

            .jus-between {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 24px;
            }

            .input-voucher {
                border: none;
                color: $yellow-1;
                width: 100%;
                justify-content: start;
                align-items: center;
            }
        }
    }
#popup-modal{
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    .popup-container{
        background-color: $white;
        padding: 16px;
        border-radius: 8px;
        min-width: 500px;
        .popup-head{
            display: flex;
            .popup-head-icon{
                margin-right: 20px;
            }
            .popup-head-detail{
                .detail-title{
                    font-weight: 600;
                    font-size: 20px;
                    color: $black-1;
                }
                .detail-des{
                    font-weight: 600;
                    font-size: 20px;
                    color: $red;
                }
            }
        }
        .voucher-info-text{
            font-weight: 500;
            font-size: 14px;
            color: $black;
            margin-top: 24px;
            margin-bottom: 8px;
        }
        .detail-info{
            background-color: $line;
            padding: 16px;
            width: 35vw;
            min-width: 500px;
            border-radius: 8px;
            margin-bottom: 16px;
            .block-detail{
                .row-detail{
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 16px;
                    .detail-left{
                        font-weight: 500;
                        font-size: 14px;
                        color: $gray-2;
                    }
                    .detail-right{
                        font-weight: 600;
                        font-size: 14px;
                        color: $black;
                    }
                    .txt-bg {
                        background-color: $green;
                        padding: 1px 8px;
                        border-radius: 20px;
                    }

                    .txt-bg-blue {
                        background-color: $blue-5;
                        padding: 1px 8px;
                        border-radius: 20px;
                    }
                }
                .mgb-0 {
                    margin-bottom: 0px !important;
                }
            }
           
            .grid-container {
                .block-detail-row {
                    border-right: 1px solid $color-border-1;
                    padding: 0px 5px 0px 0px;
                }
            }
            .row-detail-dif{
                display: flex;
                justify-content: space-between;
                margin-top: 16px;
                .detail-left{
                    font-weight: 500;
                    font-size: 16px;
                    color: $black;
                }
                .detail-right{
                    font-weight: 600;
                    font-size: 16px;
                    color: #DC8108;
                }
            }
           
        }
        .notice-bar{
            margin-top: 14px;
            background-color: #E54959;
            display: flex;
            border-radius: 8px;
            padding-top: 12px;
            padding-bottom: 12px;
            padding-left: 16px;
            padding-right: 16px;
            .notice-text{
                font-size: 14px;
                font-weight: 500;
                color: $white;
                margin-left: 10px;
            }
        }
        .button-contain{
            margin-top: 32px;
            display: flex;
            justify-content: space-between;
            .button-left{
                display: flex;
                flex: 0.45;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                font-weight: 600;
                color: $black;
                border: 1px solid $color-border;
                border-radius: 4px;
                height: 44px;
                cursor: pointer;
            }
            .button-right{
                display: flex;
                flex: 0.45;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                font-weight: 600;
                color: $gray-1;
                background-color: $brown-2;
                border-radius: 4px;
                height: 44px;
                cursor: pointer;
            }
        }
    }
}
#popup-print{
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    .popup-print-container{
        background-color: $white;
        border-radius: 8px;
        padding: 24px;
        min-width: 320px;
        .popup-print-top{
            width: 100%;
            .header{
                display: flex;
                align-items: center;
            }
        }
        .button-contain{
            margin-top: 32px;
            display: flex;
            justify-content: space-between;
            .button-left{
                display: flex;
                flex: 0.45;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                font-weight: 600;
                color: $black;
                border: 1px solid $color-border;
                border-radius: 4px;
                height: 44px;
                cursor: pointer;
            }
            .button-right{
                display: flex;
                flex: 0.45;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                font-weight: 600;
                color: $gray-1;
                background-color: $brown-2;
                border-radius: 4px;
                height: 44px;
                cursor: pointer;
            }
        }
    }
}

.container-my-input {
    width: 100%;
    display: flex;
    flex-direction: column;
    .input-discount-payment{
        display: flex;
        flex-direction: row;
        padding: 5px 16px;
        background-color: $white;
        border-radius: 5px;
        position: relative;
        height: 40px;
        width: 100%;
        text-transform: uppercase;
       }
}

.drops-down-money {
  border: 1px solid $brown;
  width: 150px;
  margin-left: 16px;
  .MuiButtonBase-root {
    background-color: $white;
    border: none;
    width: 100%;
    box-shadow: none;
    justify-content: start;
    background-color: $brown-1 !important;
  }
    svg {
        fill: $brown !important;
    }
  border-radius: 5px;
  height: 40px !important;
}

.drops-down-customer {
    border: none;
    // width: 100px;
    width: fit-content;
    margin-left: 16px;
    .MuiButtonBase-root {
      background-color: $white;
      border: none;
      width: 100%;
      box-shadow: none;
      justify-content: start;
      background-color: $brown-1 !important;
    }
      svg {
          fill: $brown !important;
      }
    border-radius: 5px;
    height: 40px !important;
  }

  .drops-down-popup-customer {
    border: none;
    // width: 100px;
    width: fit-content;
    padding: 0px !important;
    background-color: $trans !important;

    .MuiButtonBase-root {
      background-color: $trans;
      border: none;
      width: 100%;
      box-shadow: none;
      justify-content: start;
    }
      svg {
          fill: $brown !important;
      }
    border-radius: 5px;
  }

.jus-end {
    justify-content: space-between;
    align-items: start;
}

.jus-start {
    justify-content: space-between;
    align-items: start;
}

.camera-box {
    width: 40px;
    margin-left: 16px;
    background-color: $gray-3;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

.order-list-child {
    margin-left: -8px !important;
    margin-right: -8px !important;
}

.bill-item {
    display: flex;
    align-items: center;
    padding: 16px 0px;
    border-bottom: 1px dashed $color-border;
}

.page-payment-container {
    display: flex;
    padding: 24px;
    flex-direction: column;
    .MuiTabPanel-root {
        padding: 0px;
    }
}

.table-payment-view {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
}

.page-payment-view {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
}

.payment-icon-right {
    position: absolute;
    right: 16px;
}

.discount-icon-right {
    position: absolute;
    right: 0px;
}

.width-input {
    width: 100%;
    padding-left: 16px;
}

 .header-payment {
    width: 100%;
    background-color: $white;
    display: flex;
    flex-direction: row;
    padding-bottom: 16px;
    padding-left: 16px;
    border-bottom: 1px solid $color-border;
}

.width-payment {
    width: 55vw;
    min-width: 786px;
}

.popup-payment {
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    min-height: 80vh;
    &:focus-visible{
        outline: none;
    } 

    .popup-container{
        background-color: $white;
        padding-top: 16px;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
    .body-popup-payment {
        background-color: $line;
        display: flex;
        flex-direction: row;
        border-radius: 8px;
        flex: 1;
        .payment-option {
            display: flex;
            padding: 16px;
            flex-direction: column;
            flex: 2;
            background-color: $line;
            border-radius: 8px;
        }
    
        .payment-detail {
            display: flex;
            flex-direction: column;
            background-color: $white;
            padding: 16px;
            flex: 1;
            border-bottom-right-radius: 8px;
        }

        .payment-detail-view {
            display: flex;
            flex-direction: column;
            background-color: $white;
            flex: 1;
        }
    
        .option {
            display: flex;
            flex-direction: column;
            background-color: $white;
            border-radius: 6px;
             padding: 16px;
            .view-item {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
            }
            .item-option {
                padding: 0px 16px;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 6px;
                position: relative;
                .ic-option {
                    position: absolute;
                    top: -4px;
                    left: 0;
                }
            }
    
            .border-active {
                border: 1px solid $brown-2;
            }
            .border-blur {
                border: 1px solid $color-border;
            }
    
            .option-child {
                display: flex;
                width: 100%;
                margin-top: 16px;
                .payment-direc {
                    display: flex;
                    flex-direction: column;
                }
            }
           
        }
        .city-ledger {
            display: flex;
            flex-direction: column;
            width: 100%;
            .body-city {
                width: 100%;
                display: flex;
                flex-direction: column;
                background-color: $line;
                padding: 12px 16px;
                border-radius: 4px;
                
            }
            .building-view {
                width: 100%;
               display: flex;
               justify-content: space-between;
            }
        }
    }
    .center {
        align-items: center;
    }
    .body-city-item {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .line {
        border-bottom: 1px solid $line;
    }

    .btn-pay {
        width: 100%;
        .MuiButtonBase-root {
            background-color: $primary;
            box-shadow: none;
            border: none;
            justify-content: center;
            width: 100%;
            height: 100%;
            align-items: center;
          }
    }
 }

 .popup-container:focus-visible {
    border: none !important;
    outline: none;
 }

} 

.color-yellow {
    color: $yellow-5 !important;
  }

  .MuiBox-root {
    &:focus-visible{
        outline: none;
    } 
  }
.flex-1{ 
    flex: 1;
}
.popup-success {
   padding: 16px;
   min-width: 300px;
   justify-content: center;
   align-items: center;
   display: flex;
   flex-direction: column;
}

.button-left{
    display: flex;
    flex: 0.45;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    color: $black;
    border: 1px solid $color-border;
    border-radius: 4px;
    height: 44px;
    background-color: $color-border;
    cursor: pointer;
}

.dropdown-location-payment{
    .btn-dropdown{
        border: 1px solid #DFE2E5;
        display: flex;
        border-radius: 5px;
        cursor: pointer;
        padding: 8px 16px;
        height: 40px;
        justify-content: space-between;
        width: 200px;
        align-items: center;
        background-color: $white;
        .label{
            padding: 0px 70px 0px 5px;
        
        }

        .text-location {
            display: -webkit-box;
            line-height: 1.3;
            -webkit-line-clamp: 1;  /* số dòng hiển thị */
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .list-dropdown-item{
        position: absolute;
       
    }
    .buttons{
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        .button-print{
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid $color-border;
            border-radius: 5px;
            height: 44px;
            width: 100%;
            font-weight: 600;
            font-size: 14px;
            color: $black;
            cursor: pointer;
            width: 100%;
            padding: 3px 16px;
        }
    }
}
