@import '../../assets/styles/';

.page-order-paid{
    display: flex;
    padding: 24px;
    flex-direction: column;
}

.page-merge-view{
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    .merge-btn-right {
        background-color: $red1;
        border-radius: 5px;
        .label {
           font-size: 14px;
           color: $red;
           font-weight: 600;
           margin-left: 5px;
        }
    }
}

.tab-list {
    .MuiButtonBase-root {
        text-transform: none;
    }
}

.btn {
    padding: 0px;
    margin-bottom: 16px;
    width: 200px;
    height: 40px;
    border: 1px solid $color-border !important;
    position: relative;
    .MuiButtonBase-root {
      background-color: $white;
      box-shadow: none;
      border: none;
      justify-content: start;
      width: 100%;
      height: 100%;
    }

  }

  .width-100 {
    width: 100%;
  }
  .padding {
    padding: 5px 16px;
  }

.table-view-merge {
    max-height: calc(100vh - 274px);
    overflow-y: auto;
}

.input{
 display: flex;
 flex-direction: row;
 padding: 5px 16px;
 background-color: $white;
 border-radius: 5px;
 position: relative;
 height: 40px;
 width: 28vw;
 max-width: 450px;
 text-transform: uppercase;
}

.jus-between {
    display: flex;
    justify-content: space-between;
}

.input-style {
    border: none;
}

.merge-container {
    display: flex;
    width: 100%;
    bottom: 0px;
    height: 80px;
    background-color: $white;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    padding: 24px;
   
    .color-orange {
        background-color: $orange;
        padding: 4px 12px;
        border-radius: 5px;
        margin: 0px 5px;
    }

    .color-yellow {
        background-color: $yellow;
        padding: 4px 12px;
        border-radius: 5px;
        margin: 0px 5px;
        display: flex;
        align-items: center;

    }
    .btn-merge {
        .MuiButtonBase-root {
          background-color: $primary;
          box-shadow: none;
          border: none;
          justify-content: center;
          width: 80%;
          height: 100%;
          align-items: center;
        }
    }
    .center {
        display: flex;
        align-items: center;
    }

    .center-jus {
        justify-content: center;
    }
    .just-start{
        justify-content: flex-start;
    }
}

.icon-right-drops-merge {
    position: absolute;
    right: 16px;
}
.flex-row-merge{
    width: 100%;
    justify-content: space-between;
}
.merge-footer-left{
    display: flex;
    gap: 15px;
}
