.menu-left {
  .title-menu{
    align-items: center;
    height: 68px;
    padding-left: 20px;
    cursor: pointer;
    .menu-name {
      margin-left: 8px;
      font-weight: 600;
      font-size: 20px;
      color: #0a0b0f;
    }
    .tent-right{
        display: flex;
    }
  }
}
.flex{
    display: flex;
    gap:10px;
    align-items: center;
}
.text_7A7A7B{
    color: #7A7A7B;
}
.text_size_14{
    font-size: 14px;
}
.overflowX-auto{
    overflow-y: auto;
}
.flex-1{
    flex:1
}
.content-left{
    width: calc(100% - 420px);
}
.tagItems::-webkit-scrollbar {
    height: 5px;
}
.tagItems::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #FFA731; 
    border-radius: 10px;
}
.tagItems::-webkit-scrollbar-thumb {
    background: #B98868; 
    border-radius: 10px;
}
.tagItems::-webkit-scrollbar-thumb:hover {
    background: #FFA731; 
  }
.itemlabel{
    padding: 4px 12px;
    background: #FFFFFF;
    border-radius: 100px;
    margin: 15px;
    
}
.tagItems{
    overflow-x: auto;
    overflow-y: hidden;
}
