@import '../../assets/styles/';
.react-flow__node-selectorNode {
    font-size: 12px;
    background: #eee;
    border: 1px solid #555;
    border-radius: 5px;
    text-align: center;
}
.container-flow {
    position: relative;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    background-color: #f2f5f7;
    z-index: 9;
    font-size: 14px;
    display: contents;
    flex-direction: row;
    .flow-chat-builder {
        width: 100%;
        height: calc(100vh - 150px);
        position: relative;
        display: flex;
        flex-direction: row;
        z-index: 10;
        .flow-builder-menu-left {
            transition: transform 0.5s ease-in-out;
            transform: translate3d(calc(-100% - 1px), 0, 0);
            z-index: 10;
            .menu-left {
                width: 220px;
                height: 100%;
                .builder-part {
                    position: relative;
                    overflow: hidden;
                    width: 100%;
                    height: 100%;
                    background-color: white;
                    .menu-header-default {
                        position: relative;
                        cursor: default;
                        height: 60px;
                        background-color: #e1e5ea;
                        justify-content: center;
                        align-items: center;
                        margin-bottom: 1rem;
                        padding-left: 3rem;
                        padding-right: 3rem;
                        .h-title {
                            position: relative;
                            overflow: hidden;
                            border: 1px solid transparent;
                            color: #232b39;
                            text-overflow: ellipsis;
                            white-space: normal;
                            font-size: 20px;
                        }
                    }
                    .message-items {
                        padding: 10px 10px;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        .message-sample {
                            position: relative;
                            width: 45%;
                            min-height: 100px;
                            max-width: 120px;
                            margin: 0px;
                            padding: 5px;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            text-align: center;
                            border: 1px solid transparent;
                            border-radius: 4px;
                            cursor: grab;
                            cursor: pointer;
                            background-color: rgba(223, 229, 236, 0.5019607843) !important;
                            margin: 3px;
                            &:hover{
                              background-color: rgba(223, 229, 236, 0.5019607843);
                            }
                            .images {
                                height: 80px;
                            }
                            .message-name{
                                font-size: 13px;
                            }
                            .message-help {
                                position: absolute;
                                top: 20px;
                                right: 3px;
                            }
                            .__react_component_tooltip {
                                width: 250px !important;
                                opacity: 1;
                            }
                        }
                    }
                }
                .close-panel {
                    position: absolute;
                    top: 0px;
                    bottom: 0px;
                    left: 100%;
                    width: 60px;
                    height: 100%;
                    cursor: pointer;
                    &:hover {
                        background-color: #dfe5ec80;
                    }
                    .icon-panel {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        padding: 4px;
                        width: 32px;
                        height: 32px;
                        border-radius: 50%;
                        background-color: #dfe5ece6;
                        color: #fff;
                        text-align: center;
                        transform: translate(-50%, -50%);
                        svg {
                            margin-left: 4px;
                            font-size: 20px;
                        }
                    }
                }
            }
        }
        .flow-builder-menu-right {
            position: absolute;
            top: 0px;
            bottom: 0px;
            right: 0px;
            z-index: 10;
            background-color: #fff;
            .menu-right {
                width: 220px;
                height: 100%;
                .builder-part {
                    position: relative;
                    overflow: hidden;
                    width: 100%;
                    height: 100%;
                    .menu-header-default {
                        position: relative;
                        cursor: default;
                        height: 60px;
                        background-color: #e1e5ea;
                        justify-content: center;
                        align-items: center;
                        margin-bottom: 1rem;
                        padding-left: 3rem;
                        padding-right: 3rem;
                        .h-title {
                            position: relative;
                            overflow: hidden;
                            border: 1px solid transparent;
                            color: #232b39;
                            text-overflow: ellipsis;
                            white-space: normal;
                            font-size: 20px;
                        }
                    }
                    .message-items {
                        padding: 10px 10px;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        .message-sample {
                            position: relative;
                            width: 50%;
                            min-height: 100px;
                            max-width: 120px;
                            margin: 0px;
                            padding: 5px;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            text-align: center;
                            border: 1px solid transparent;
                            border-radius: 4px;
                            cursor: grab;
                            cursor: pointer;
                            margin: 3px;
                            &:hover{
                              background-color: rgba(223, 229, 236, 0.5019607843);
                            }
                            .images {
                                height: 80px;
                            }
                            .message-name{
                                font-size: 13px;
                            }
                            .message-help {
                                position: absolute;
                                top: 20px;
                                right: 3px;
                            }
                            .__react_component_tooltip {
                                width: 250px !important;
                                opacity: 1;
                            }
                        }
                    }
                }
                .close-panel {
                    position: absolute;
                    top: 0px;
                    bottom: 0px;
                    left: 100%;
                    width: 60px;
                    height: 100%;
                    cursor: pointer;
                    &:hover {
                        background-color: #dfe5ec80;
                    }
                    .icon-panel {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        padding: 4px;
                        width: 32px;
                        height: 32px;
                        border-radius: 50%;
                        background-color: #dfe5ece6;
                        color: #fff;
                        text-align: center;
                        transform: translate(-50%, -50%);
                        svg {
                            margin-left: 4px;
                            font-size: 20px;
                        }
                    }
                }
            }
        }
        .show-menu {
            transform: translateZ(0);
        }
        .react-flow {
            height: calc(100% - 20px);
            overflow: hidden;
            position: relative;
            width: 100%;
            z-index: 2;
            .react-flow__panel {
                display: none;
              }
        }
   
    }
    .action-save{
        // position: absolute;
        // top: 10px;
        // right: 10px;
        height: 35px;
        width: 90px;
        background: white;
        border: 1px solid $brown-2;
        font-weight: 500;
        font-size: 14px;
        color: $brown-2;
        align-items: center;
        display: flex;
        justify-content: center;
        border-radius: 5px;
        cursor: pointer;
    }

    .action-edit{
        // position: absolute;
        // top: 10px;
        // right: 120px;
        height: 35px;
        min-width: 90px;
        background: white;
        border: 1px solid $brown-2;
        font-weight: 500;
        font-size: 14px;
        color: $brown-2;
        padding: 3px 8px;
        align-items: center;
        display: flex;
        justify-content: center;
        border-radius: 5px;
        cursor: pointer;
    }
    .action-edit::backdrop {
        background-color: $brown;
    }
}

.saturate { filter: saturate(3); }
