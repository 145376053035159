@import '../../../assets/styles/';

.layout-header-order{
    height: 64px;
    background-color: $white;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
    border-bottom: 1px solid $color-border;
    display: flex;
    .btn-order-header {
        border: none !important;
    }
    .content-left{
        align-items: center;
        .label{
            margin-left: 30px;
        }
        .select-order{
            cursor: pointer;
            .name-order{
                margin:0px 25px 0px 15px;
            }
        }
    }
    .content-right{
        align-items: center;
        .type-meal{
            height: 36px;
            padding: 0px 15px;
            display: flex;
            align-items: center;
            margin-left: 20px;
            cursor: pointer;
        }
        .dropdown-table{
            .btn-dropdown{
                display: flex;
                border-radius: 5px;
                cursor: pointer;
                align-items: center;
                .label{
                    padding: 0px 70px 0px 5px;
                }
            }
            .list-dropdown-item{
                position: absolute;
               
            }
            .drops {
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 100%;
            }
        }
    }
}

#demo-customized-menu{
    .MuiButtonBase-root{
        border-bottom: 1px dashed #e9ecef;
    }
}


.btn-table-header {
    padding: 0px;
    width: 200px;
    height: 40px;
    padding: 5px 16px;
    border: 1px solid $color-border !important;
    position: relative;
    .icon-right-drops {
      position: absolute;
      right: 0px;
    }
}
