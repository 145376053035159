@import '../../assets/styles/';

.button {
    text-transform: none !important;
    
}

.icon-right {
    position: absolute;
    right: 16px;
}

.button-style-container {
    padding: 0px;
    width: 200px;
    height: 40px;
    .MuiButtonBase-root {
      box-shadow: none;
      border: none;
      justify-content: start;
      width: 100%;
      height: 100%;
    }

  }
