.text-normal {
    font-size: 14px;
}

.text-small {
    font-size: 12px;
}

.text-small-x {
    font-size: 10px;
}

.text-big {
    font-size: 16px;
}

.text-big-x {
    font-size: 20px;
}

.bold{
    font-weight: 600;
}

.regular{
    font-weight: 400;
}

.color-black {
  color: $black;
}

.color-green {
    color: $green;
  }

.color-gray {
    color: $text;
}

.color-gray-1 {
    color: $gray-2;
}

.color-red {
    color: $red;
}

.color-black-1 {
    color: $black-1;
}

.color-white {
    color: $white;
}

.color-yellow-5 {
    color: $yellow-5;
}

.color-brown {
    color: $brown !important;
}

.color-text-2{
    color: $text-2 !important;
}



.border {
  border: '1px solid $gray-3';
} 

//margin 

.mgr-5 {
    margin-right: 5px;
}

.mgr-10 {
    margin-right: 10px;
}

.mgl-5 {
    margin-left: 5px;
}

.mgl-10 {
    margin-left: 10px;
}
.mgl-16 {
    margin-left: 16px;
}

.mgr-24 {
    margin-right: 24px;
}

.mgt-3{
    margin-top: 3px;
}

.mgt-16{
    margin-top: 16px;
}

.mgt-24 {
    margin-top: 24px;
} 

.mgb-10 {
    margin-bottom: 10px;
}

.mgb-5 {
    margin-bottom: 5px;
}

.mgb-16 {
    margin-bottom: 16px;
}

//padding 

.pd-5 {
    padding: 5px;
}

.pd-10 {
    padding: 10px;
}

.pd-16 {
    padding: 16px;
}


//shadow
.shadow {
    box-shadow:  0px 4px 8px $gray-2;
}

//radius 

.radius {
    border-radius: 16px;
}

//flex

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

// font

.italic {
    font-style: italic;
}

.space-nowrap {
    white-space: nowrap;
}
