@import '../../assets/styles/';

.modal-print{
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    .popup-print-container{
        background-color: $white;
        border-radius: 8px;
        padding: 24px;
        min-width: 320px;
        .popup-print-top{
            width: 100%;
            .header{
                display: flex;
                align-items: center;
            }
        }
        .button-contain{
            margin-top: 32px;
            display: flex;
            justify-content: space-between;
            .button-left{
                display: flex;
                flex: 0.45;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                font-weight: 600;
                color: $black;
                border: 1px solid $color-border;
                border-radius: 4px;
                height: 44px;
                cursor: pointer;
            }
            .button-right{
                display: flex;
                flex: 0.45;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                font-weight: 600;
                color: $gray-1;
                background-color: $brown-2;
                border-radius: 4px;
                height: 44px;
                cursor: pointer;
            }
        }
    }
}
