@import '../../assets/styles/';

.layout-header-01{
    height: 64px;
    background-color: #fff;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
    border-bottom: 1px solid #D9D9D9;
    .content-left{
        .title-logo{
            align-items: center;
            .table-title{
                color: #301403;
                font-size: 20px;
                font-weight: 500;
                margin-left: 15px;
                border-right: 1px solid #D9D9D9;
                padding-right: 40px;
            }
        }
        
        .btn-table-map{
            height: 36px;
            align-items: center;
            margin-left: 40px;
            border-radius: 20px;
            padding: 0px 10px;
            cursor: pointer;
            .label{
                color: #595959;
                font-weight: 600;
                margin-left: 8px;
                font-size: 16px;
            }
        }
        .btn-order-paid{
            height: 36px;
            align-items: center;
            margin-left: 20px;
            border-radius: 20px;
            padding: 0px 15px;
            cursor: pointer;
            .label{
                color: #595959;
                margin-left: 8px;
                font-weight: 600;
                font-size: 16px;
            }
        }
        .active{
            background-color: #FBF3EF;
            .label{
                color: #B98868;
                margin-left: 8px;
                font-weight: 600;
                font-size: 16px;
            }
        }
    }
    .content-right{
        align-items: center;
        .btn-payment{
            background: #B98868;
            height: 36px;
            padding: 0px 20px;
            border-radius: 20px;
            align-items: center;
            margin-right: 20px;
            cursor: pointer;
            .label{
                color: #fff;
                margin-left: 8px;
            }
        }
    }
}
.layout-header-02{
    justify-content: space-between;
    height: 68px;
    padding: 0px 20px;
    background-color: #fff;
    align-items: center;
        .content-left{
            align-items: center;
            // justify-content: center;
            .logo{
                align-items: center;
                .label{
                    color: #0A0B0F;
                    font-size: 20px;
                    font-weight: 600;
                    margin-left: 10px;
                }
            }
            .description{
                margin-left: 60px;
            }
            .des-table-empty{
               .label{
                margin-left: 10px;
                color: #595959;
                font-size: 14px;
               }
            }
            .des-table-customer{
                margin-left: 20px;
                .label{
                    margin-left: 10px;
                    color: #595959;
                    font-size: 14px;
                   }
            }
        }
        .dropdown-location{
            .btn-dropdown{
                border: 1px solid #DFE2E5;
                display: flex;
                border-radius: 5px;
                cursor: pointer;
                padding: 8px 16px;
                align-items: center;
                .label{
                    padding: 0px 70px 0px 5px;
                }
            }
            .list-dropdown-item{
                position: absolute;
               
            }
            .buttons{
                display: flex;
                justify-content: space-between;
                flex-direction: row;
                .button-print{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 1px solid $color-border;
                    border-radius: 5px;
                    height: 44px;
                    font-weight: 600;
                    font-size: 14px;
                    color: $black;
                    cursor: pointer;
                    width: 100%;
                    padding: 3px 16px;
                }
            }
        }
}
#demo-customized-menu{
    .MuiButtonBase-root{
        border-bottom: 1px dashed #e9ecef;
    }
}

.text-user {
    p{
        color: #202223;
        font-weight: 500;
        font-size: 14px;
        margin-left: 5px;
    }
}


.text-red {
    p{
        color: #DC3545;
        font-weight: 500;
        font-size: 14px;
        margin-left: 5px;
    }
}

