@import '../../assets/styles/index.scss';

*:before,
*:after {
  box-sizing: inherit;
}

* {
  margin: 0px;
  padding: 0px;
}

.th-content {
  overflow-x: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  padding: 8px;
}

.td {
  overflow: hidden;
  color: $black-1;
  align-items: stretch;
  display: flex;
  flex-direction: column;
  padding: 0px !important;
  justify-content: center;
  align-items: start;
}

.table {
  border-spacing: 0;
  border: none;
  padding: 0px !important;
  background-color: $white;
  border-radius: 8px;
  box-shadow: 0px 4px 8px $box-shadow;
  display: block;
  position: relative;
  .Mui-checked {
    color: $orange !important;
  }
  .MuiCheckbox-indeterminate {
    color: $orange !important;
  }
}

.th,
.td {
  margin: 0;
  border-bottom: 1px solid $color-border;
  position: relative;
}

.border-left {
  border-left: 1px solid $color-border;
}

.text-align-right {
  text-align: right;
}

.align-items-center {
  align-items: center;
}

.label-table {
  margin: 8px;
}

.cell-container {
  display: flex;
  flex-direction: row;
  .th {
    padding: 0px !important;
    display: flex;
    align-items: center;
  }
  
}

.cell-container:active{
  background-color: $yellow;
  svg {
    path {
      fill: $yellow-1;
    }
  }
}

.icon {
  justify-content: center !important;
  align-items: center;
  display: flex;
  object-fit: contain;
  flex: 1;
  width: 100%;
  padding: 0px !important;
 
}

.icon:active{
  svg {
    path {
      fill: $yellow-1;
    }
  }
 }

.drop-label {
  font-size: 14px;
  color: $gray-2;
  padding: 0px 5px;
}

.btn:hover {
  .MuiButtonBase-root {
    background-color: $gray-1;
  }
}

.flex-col {
  flex-direction: column;
  display: flex;
}

.header {
  display: flex;
  flex-direction: row;
  position: sticky;
  top: 0px;
  background-color: $white;
  z-index: 1000;
}

.merge {
    position: sticky;
    bottom: 0px;
    background-color: $white;
    width: 100%;
    height: 50px;
}

