@import '../../../../assets/styles/';

.container-column-flow{
   width: 80px;
   height: 80px;
   background-color: $gray-2;
   justify-content: center;
   align-items: center;
   display: flex;
}
