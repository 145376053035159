@import '../../../src/assets/styles/';

.oder-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
}

.item-order-chill {
    display: flex;
    flex: 1;
    flex-direction: row;
    padding: 16px !important ;
}

.view-img {
    display: flex;
    flex-direction: row;
}

.img {
    width: 56px;
    height: 56px;
    border-radius: 5px;
}

.line {
    border-bottom: 1px solid $line;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-column {
    display: flex;
    flex-direction: row;
}

.flex-1 {
    flex: 1;
}

.flex-2 {
    flex: 2;
}

.al-end {
    align-items: end;
}

.al-center {
    align-items: center;
}

.txt-del {
    text-decoration: line-through;
}
