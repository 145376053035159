@import '../../assets/styles/';

.button {
    text-transform: none !important;
}

.color {
  color: $text !important;
}

.drop-down {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    padding: 5px 16px;
    height: 100%;
    background-color: white;
    border-radius: 5px;
    .center {
        display: flex;
        align-items: center;
    }
    .drops-main {
        width: 100%;
        display: flex;
        position: relative;
        justify-content: start;
        align-items: center;
    }
}

