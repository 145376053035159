#root{
    background: rgba(4, 125, 70, 0.05);
    // font-family: 'Open Sans', sans-serif;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    height: 100%;
}
#page-main {
    max-width: 1194px;
    height: 100vh;
    margin: auto;
    background: #F0F1F2;
  }
 body {
    height: fit-content;
    /* mobile viewport bug fix */
     min-height: -webkit-fill-available;
    width: 100%;
    padding: 0;
    margin: 0;
    overflow: hidden;
  }
  html {
    height: -webkit-fill-available;
  }
