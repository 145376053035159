@import '../../../../assets/styles/';
.node {
    min-height: 10px;
    height: 100%;
    max-height: 20px;
    min-width: 100px;
    box-sizing: border-box;
    background-color: $gray-2;
    .rotate-handle {
        position: absolute;
        width: 10px;
        height: 10px;
        background: $brown-2;
        left: 50%;
        top: -50px;
        border-radius: 100%;
        transform: translate(-50%, -50%);
        cursor: alias;
      }
      
      .rotate-handle:after {
        content: "";
        display: block;
        position: absolute;
        width: 1px;
        height: 50px;
        background: $brown-2;
        left: 4px;
        top: 5px;
      }
  }
  
  .node :global .react-flow__resize-control.handle {
    width: 5px;
    // height: 5px;
  }

  