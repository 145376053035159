.page-home {
    background-color: #F0F1F2;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 85%;
  .table-map {
    display: flex;
    height: 100%;
    flex-direction: column;
    .content-list-table {
        display: flex;
        justify-content: center;
        width: 100%;
        height: calc(100% - 76px);
        .list-content {
          width: 100%;
          height: 100%;
          flex-direction: row;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          overflow-y: scroll;
          padding: 0px 10px;
          .item {
            margin: 40px;
            position: relative;
            background-image: url("./../../../public/assets/Table.png");
            width: 205px;
            height: 192px;
            cursor: pointer;
            .content{
                width: 100%;
                height: 100%;
                justify-content: center;
                align-items: center;
                display: flex;
                .label {
                    font-weight: 700;
                    font-size: 20px;
                    height: 72px;
                    width: 72px;
                 
                    color: #fff;
                    border-radius: 36px;
                    margin-bottom: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0 auto;      
                    margin-bottom: 10px;      
                  }
                  .list-order{
                    background-color: #FFF4E6;
                    padding: 5px 10px;
                    color: #595959;
                    font-size: 12px;
                    font-weight: 400;
                    border-radius: 20px;
                    text-align: center;
                  }
            }
           
          }
          .item-active {
            .label {
                background-color: #2DBF7D;
            }
          }
          .item-active-full {
            .label {
                background-color: #FFA731;
              }
          }
        }
      }
  }
}
