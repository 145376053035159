$black: #0A0B0F;
$text: #595959;
$gray-1: #f5f5f5;
$white: white;
$black-1: #424242;
$yellow: #FFF4E6;
$yellow-1: #edb367;
$color-border: #e0e0e0;
$box-shadow: rgba(0, 0, 0, 0.08);
$gray-2: #9C9C9E;
$line: #F0F0F0;
$gray-3: #DFE2E6;
$red: #B51929;
$black-1: #3D3E3E;
$green: #19975F;
$primary:#B98868;
$orange: #DC8108;
$brown: #874D28;
$brown-1: #F6EBE4;
$brown-2: #B98868;
$yellow-5: #FFA731;
$blue: #139fdc;
$red1: #FFF3F4;
$color-border-1: #CBCBCD;
$trans: transparent;
$blue-5: #2F549F;
$text-2: #7A7A7B
