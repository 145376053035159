@import '../../assets/styles/';
.page-order {
  display: flex;
  width: 100%;
  height: calc(100vh - 64px);
  .page-order-menu{
  }
//   .page-order-dish{
//     background: #F0F1F2;
//   }
  .page-order-cart{
    .content-order {
      background-color: #fff;
      height: 100%;
      position: relative;
      width: 366px;
      display: flex;
      flex-direction: column;
      .header {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 20px 20px 0px 20px;
        height: 12%;
        .first-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;
          .name-table {
            display: flex;
            align-items: center;
            
            .label{
              margin-left: 3px;
              font-size: 20px;
              font-weight: 600;
              color: #0A0B0F;
            }
          }
          .header-right-order {
            .time {
              display: flex;
              justify-content: center;
              align-items: center;
              background: #f0f1f2;
              border-radius: 100px;
              font-weight: 500;
              font-size: 12px;
              margin-left: 10px;
              padding: 5px 10px;
              gap: 5px;
              color: white;
              background-color: #f79009;
            }
          }
        }
        .header-below-order {
          display: flex;
          .location {
            background: #f0f1f2;
            border-radius: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 500;
            font-size: 12px;
            padding: 5px 10px;
            gap: 5px;
          }
          .meal {
            display: flex;
            justify-content: center;
            align-items: center;
            background: #f0f1f2;
            border-radius: 100px;
            font-weight: 500;
            font-size: 12px;
            margin-left: 10px;
            padding: 0px 10px;
          }
        }
      }
      .content {
        padding: 0px 20px 20px 20px;
        height: 65%;
        margin-top: 10px;
        .MuiTabPanel-root {
            padding: 0px !important;
        }
        .lable {
          margin-top: 24px;
          display: flex;
          justify-content: space-between;
          color: #474a4c;
          font-size: 14px;
          font-weight: 600;
        }
        // css cho scrool bar
        .scrollbar {
          background-color: #f5f5f5;
          float: left;
          margin-bottom: 25px;
          margin-left: 22px;
          margin-top: 40px;
          width: 65px;
          overflow-y: scroll;
        }
        #style-1::-webkit-scrollbar {
          width: 6px;
          background-color: #f5f5f5;
        }
        #style-1::-webkit-scrollbar-thumb {
          background-color: #000000;
        }
        .force-overflow {
          min-height: 450px;
        }
        .item-list {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0px;
          gap: 20px;
          width: 100%;
          height: 100%;
          overflow-y: scroll;
          /* Inside auto layout */
          flex: none;
          order: 1;
          align-self: stretch;
          flex-grow: 0;
          .item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            padding: 5px;
            width: 100%;
            .font-big {
              font-weight: 600;
              font-size: 14px;
              line-height: 20px;
            }
            .font-normal {
              color: #6d7175;
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 150%;
            }
            .main-item {
              display: flex;
              flex-direction: row;
              align-items: flex-start;
              padding: 0px;
              gap: 24px;
              justify-content: space-between;
              width: 100%;
              height: auto;
              .item-info {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                padding: 0px;
                gap: 12px;
              }
              .item-value {
                .item-quantity {
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                  // gap: 10px;
                  text-align: center;
                  gap: 5px;
                }
              }
            }
            .note {
              display: flex;
              flex-direction: row;
              align-items: flex-start;
              padding: 0px;
              width: 100%;
              justify-content: space-between;
              .note-style {
                margin-top: 5px;
                font-style: italic;
                border: none;
                width: 170px;
                overflow: hidden;
                resize: none;
                // margin-left: 10px;
                &:focus-visible {
                  outline: none;
                }
              }
    
              .note-label {
                color: #CAA37D;
                font-weight: 400;
                font-size: 12px;
                line-height: 150%;
              }
              .note-icon {
                margin-bottom: 10px;
                line-height: 150%;
                display: flex;
                gap: 5px;
                cursor: pointer;
              }
            }
            &:last-child{
                padding-bottom: 50px;
            }
          }
        }
      }
    
      .footer {
        padding: 12px;
        background: #fdfdfd;
        width: 100%;
        box-shadow: 0px -2px 8px -2px rgba(32, 34, 35, 0.1),
          0px 0px 4px -2px rgba(32, 34, 35, 0.06);
        .total-value {
          padding: 10px 0;
          align-self: stretch;
          gap: 12px;
          height: fit-content;
          .total-people {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            padding: 0px;
            gap: 6px;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
          }
          .total-price {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 0px;
            gap: 10px;
            flex: none;
            order: 0;
            align-self: stretch;
            flex-grow: 0;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 150%;
          }
        }
        .order-button {
          padding: 12px 24px;
          color: #ffffff;
          gap: 10px;
          width: 100%;
          background: #a6815b;
          background: #a6815b;
          border-radius: 8px;
          border: none;
        }
      }
    }
  }
  .header-menu {
    height: 68px;
    background: #ffffff;
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 12px 24px !important;
    .content-left {
        align-items: center;
        // padding-left: 20px;
      .action-menu {
        cursor: pointer;
        .label {
          font-weight: 600;
          font-size: 20px;
          color: #0a0b0f;
          margin-left: 5px;
        }
      }
    }
    .content-right{
      .search{
        border: 1px solid #DFE2E6;
        border-radius: 8px;
        input{
          border: 0px;
        }
      }
    }
  }
  .menu-content2 {
    width: 0px;
    transform: translateX(-1000px);
    transition: 0.5s;
    background: #fdfdfd;
    box-shadow: 0px 12px 16px -4px rgba(32, 34, 35, 0.08),
      0px 4px 6px -2px rgba(32, 34, 35, 0.03);
    .title-menu {
      display: flex;
      flex-direction: row;
      border-bottom: solid 1px#DFE2E5;
      align-items: center;
      img {
        margin-right: 5px;
      }
    }
    .title-cate {
      background-color: #caa37d;
      color: #fdfdfd;
      font-size: 16px;
      font-weight: 600px;
      justify-content: space-between;
      display: flex;
      flex-direction: row;
      padding: 6px 16px;
      border-radius: 6px 6px 0px 0px;
    }
    .title-cate-notchoose {
      padding: 6px 16px;
      color: #202223;
      font-size: 16px;
      font-weight: 600px;
      justify-content: space-between;
      display: flex;
      flex-direction: row;
    }
    .cate {
      padding: 12px 16px;
      border-radius: 6px;
    }
    .title-sub-cate {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 12px 16px;
      cursor: pointer;
    }
    .detail-cate {
      background-color: #a6815b1a;
      border-radius: 6px;
    }
    .subItemChoose {
      padding-left: 32px;
      color: #caa37d;
    }
    .title-sub-cate {
      color: #caa37d;
    }
  }
  .menu-content1 {
    width: 244px;
    transform: translateX(0px);
    transition: 0.5s;
    background: #fdfdfd;
    box-shadow: 0px 12px 16px -4px rgba(32, 34, 35, 0.08),
      0px 4px 6px -2px rgba(32, 34, 35, 0.03);
    .title-menu {
      display: flex;
      width: 100%;
      flex-direction: row;
      border-bottom: solid 1px#DFE2E5;
      align-items: center;
      img {
        margin-right: 5px;
      }
    }
    .title-cate {
      width: 100%;
      background-color: #caa37d;
      color: #fdfdfd;
      font-size: 16px;
      font-weight: 600px;
      justify-content: space-between;
      display: flex;
      flex-direction: row;
      padding: 6px 16px;
      border-radius: 6px 6px 0px 0px;
    }
    .title-cate-notchoose {
      padding: 6px 16px;
      color: #202223;
      font-size: 16px;
      font-weight: 600px;
      justify-content: space-between;
      display: flex;
      flex-direction: row;
      cursor: pointer;
    }
    .cate {
      padding: 12px 16px;
      border-radius: 6px;
    }
    .title-sub-cate {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 12px 16px;
    }
    .detail-cate {
      background-color: #a6815b1a;
      border-radius: 6px;
    }
    .subItemNotChoose {
      padding-left: 32px;
      color: #474a4c;
      cursor: pointer;
      li::marker {
        content: "";
      }
    }
    .subItemChoose {
      padding-left: 32px;
      color: #caa37d;
    }
    .title-sub-cate {
      color: #caa37d;
      cursor: pointer;
    }
  }

  .button-order-bottom {
    width: 100%;
    display: flex;
    background-color: $white;
    padding: 24px;
    flex-direction: row;
    .MuiButtonBase-root {
        border-radius: 0px;
    }
  }
}

.button-right-header {
    border-radius: 20px;
    // background-color: $blue;
    .label {
        font-size: 14px;
        color: $white;
        font-weight: 300;
        margin-left: 5px;
    }
}

.bg-breakfast{
    background-color: $blue;
}
.bg-lunch{
    background-color: #F79009;
}
.bg-dinner{
    background-color: #062B41;
}
.tagItems{
    min-height: 57px!important;
}
.itemlabel{
    cursor: pointer;
}
