@import '../../assets/styles/';
.boxGroupInput {
    display: block;
}

.label-container {
    display: flex;
    justify-content: space-between;
}

.formGroup {
    border-radius: 8px;
    position: relative;
    display: flex;
    align-items: center;
}

.errorMsg {
    border-color: $red;
}

.messageError {
    margin: 5px 0px 0 0px;
    background-color: $white;
    .text-err {
        color: $red;
        font-size: 14px;
        font-weight: 300;
    }
}

.errMsg {
    border: 1px solid $red;
    position: relative;
    animation: shake 0.9s ease 1;
}

.ic_error {
    position: absolute;
    right: 10px;
    top: 6px;
}

.icon-right-input {
    position: absolute;
    right: 10px;
    padding-left: 10px;
    z-index: 1;
    height: 16px;
}

.icon-left-input {
    padding-right: 10px;
    z-index: 1;
    height: 16px;
}

@keyframes shake {
    0%,
    100% {
        transform: translateX(0);
    }
    10%,
    30%,
    50%,
    70%,
    90% {
        transform: translateX(-1px);
    }
    20%,
    40%,
    60%,
    80% {
        transform: translateX(1px);
    }
}

.select-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    position: relative;
    font-size: 14px;
    color: $text;
}

.disable-input-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-radius: 5px;
    position: relative;
    opacity: 1;
}

.focus-input-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // width: 100%;
    border-radius: 5px;
    color: $text;
    font-size: 14px;
    border: 1px solid $brown !important;
    position: relative;
}

.error-input-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // width: 100%;
    border-radius: 5px;
    animation: shake 0.9s ease 1;
    border: 1px solid $red !important;
    position: relative;
}

.text-error-picker {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: $red;
}

input[type=date]::-webkit-calendar-picker-indicator {
    background: url(../../assets/img/img_calendar.png) no-repeat;
    cursor: pointer;
}

input[type=date]::before {
    color: $text;
    font-size: 14px;
}

input[type=date] {
    color: $white;
}

input[type=date]:focus,
input[type=date]:valid {
    color: $text;
    font-size: 14px;
}

input[type=date]:focus::before,
input[type=date]:valid::before {
    content: '' !important;
}


.input-style:focus {
    outline: none;
}

input[autoCapitalize=characters] {
    text-transform: uppercase;
}

input[autoCapitalize=words] {
    text-transform: capitalize;
}

input[autoCapitalize=none] {
    text-transform: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    // Chrome, Safari, Edge, Opera delete right arrow
    -webkit-appearance: none;
    margin: 0;
}

.icon-right-view {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 5px;
    top: 0px;
    width: 25px;
    height: 100%;
}

.input-style {
    width: calc(100% - 40px);
}
