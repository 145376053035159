@import '../../assets/styles/';
#aera-text {
    display: flex;
    border: none;
    flex-direction: column;
    .boxGroupInput {
        display: flex;
    }
    
    .formGroup {
        position: relative;
        display: flex;
        align-items: center;
    }
    
    .errorMsg {
        border-color: $red;
    }
    
    .messageError {
        margin-top: 5px;
        margin-bottom: -10px;
        background-color: $white;
        .text-err {
            color: $red;
            font-size: 14px;
        }
    }
    
    .errMsg {
        border: 1px solid $red;
        position: relative;
        animation: shake 0.9s ease 1;
    }
    
    .ic_error {
        position: absolute;
        right: 10px;
        top: 6px;
    }
    
    .icon-right {
        position: absolute;
        right: 10%;
        top: 25%;
    }
    
    .label {
        font-size: 14px;
        padding-bottom: 4px;
        span {
            color: $red
        }
    }
    
    @keyframes shake {
        0%,
        100% {
            transform: translateX(0);
        }
        10%,
        30%,
        50%,
        70%,
        90% {
            transform: translateX(-1px);
        }
        20%,
        40%,
        60%,
        80% {
            transform: translateX(1px);
        }
    }
    
    .select-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        border-radius: 5px;
        color: $gray-1;
        font-size: 14px;
    }
    
    .disable-input-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        border: 1px solid $color-border !important;
        border-radius: 5px;
        color: $gray-1;
        font-size: 14px;
        background-color: $white;
    }
    
    .focus-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        border-radius: 5px;
        color: $gray-1;
        font-size: 14px;
        border: 1px solid $brown-2 !important;
        height: 100%;
    }
    
    .error-input-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        border: 1px solid $red;
        border-radius: 5px;
        color: $gray-1;
        font-size: 14px;
        animation: shake 0.9s ease 1;
    }
    
    .text-error-picker {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        color: $red;
        font-size: 14px;
    }
    
    ::placeholder {
        color: $gray-2;
        font-size: 14px;
    }
    
    .input-style {
        display: flex;
        padding: 16px;
        resize: none;
        border: 1px solid $color-border !important;
        color: $black;
        font-size: 14px;
        border-radius: 5px;
        width: 100%;
        height: 100px;
        margin: 0px !important;
    }
    
    .label {
        margin-bottom: 5px;
    }
}
