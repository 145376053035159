.split-bill-manual .modal-content{
    width: 50vw !important;
    min-width: 800px;
}
.split-bill-manual .modal-dialog{
    max-width: 90vw !important;
}
.order-list{
    padding: 8px;
    margin: 8px;
    height: 132px;
    max-height: 132px;
    overflow-y: auto;
    &::-webkit-scrollbar-thumb {
        background: #B98868; 
        border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px #FFA731; 
        border-radius: 10px;
    }
    &::-webkit-scrollbar {
        width: 5px;
    }
    .order-item{
        display: flex;
        justify-content: space-between;
        &-left{
            &-price{
                display:flex;
                gap:10px;
                align-items: center;
            }
        }
        &-price-qty{
            display: flex;
            gap:10px;
        }
        h5{
            color:#0A0B0F;
            font-weight: 600;
            font-size:14px;
        }
        p{
            margin: 0;
        }
        &-right{
            svg{
                cursor: pointer;
            }
        }
    }
}
.order-note{
    background:#E8FEF4;
    border-radius: 4px;
    padding: 8px 12px;
    display: flex;
    justify-content: flex-start;
    gap:10px;
    align-items: center;
    margin: 20px 0;
    p{
        margin: 0;
    }
}
