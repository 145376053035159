#checkbox-cus-container{
    .checkbox-container{
        display: flex;
        flex-direction: row;
        align-items: center;
        .checkbox-label{
            margin-left: 12px;
            font-size: 14px;
            font-weight: 500;
            color: #3D3E3E;
        }
    }
}